import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchSubscription = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('authToken');
      if (!token) return;

      const response = await axios.get('https://toro-f592d52310e3.herokuapp.com/subscription', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSubscription(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log('No subscription found, redirecting to Manage Membership.');
        setSubscription(null);
      } else {
        console.error('Failed to fetch subscription:', err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  return (
    <SubscriptionContext.Provider value={{ subscription, loading, setSubscription, setLoading, fetchSubscription }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
