import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css'; // Use the correct path for the CSS file

const LandingPage = () => {
  const navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState(null);

  return (
    <div className="hero">
      {/* Navigation Bar */}
      <nav className="navbar">
        <div className="nav-logo">
          <img src="../../assets/white_on_trans_icon.png" alt="Toro Logo" className="nav-logo-img" />
        </div>
        <ul className="nav-links">
          <li className="nav-item" onClick={() => navigate('/product')}>Products</li>
          <li className="nav-item" onClick={() => navigate('/pricing')}>Pricing</li>
          <li className="nav-item" onClick={() => navigate('/resources')}>Resources</li>
        </ul>
        <div className="top-right-buttons">
          <button
            className="nav-button"
            onMouseEnter={() => setHoveredButton('login')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate('/login')}
            style={hoveredButton === 'login' ? { backgroundColor: '#FFD700', color: '#3C0301' } : {}}
          >
            Login
          </button>
          <button
            className="nav-button"
            onMouseEnter={() => setHoveredButton('signup')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate('/signup')}
            style={hoveredButton === 'signup' ? { backgroundColor: '#FFD700', color: '#3C0301' } : {}}
          >
            Sign Up
          </button>
        </div>
      </nav>

      {/* Video Background */}
      <div className="video-container">
        <video autoPlay loop muted className="background-video">
          <source src="../../assets/toro_background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Hero Section */}
      <div className="hero-content">
        <h1 className="hero-title">Effortless Livestock Management</h1>
        <p className="hero-subtitle">
          Toro combines cutting-edge technology with an easy-to-use design, making ranch operations simple.
          Whether you're a tech expert or a first-time user, Toro is built for you.
        </p>
        <div className="cta-container">
          <button className="cta-button" onClick={() => navigate('/welcome')}>
            Get Started
          </button>
          <button
            className="cta-button cta-button-outline"
            onMouseEnter={() => setHoveredButton('learnMore')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate('/contact')}
            style={hoveredButton === 'learnMore' ? { color: '#3C0301' } : {}}
          >
            Learn More
          </button>
        </div>
      </div>

      {/* USP Section */}
      <div className="usp-section">
        <div className="usp-item">
          <h2>For Non-Tech-Savvy Users</h2>
          <p>Toro’s intuitive design ensures ease of use for ranchers with little to no technical background, allowing you to focus on your operation without the frustration of complex tools.</p>
        </div>
        <div className="usp-item">
          <h2>Tailored for Your Ranch</h2>
          <p>With customizable features, Toro adapts to the needs of your ranch, whether it's herd management or device integration, ensuring every aspect fits your unique operation.</p>
        </div>
        <div className="usp-item">
          <h2>Manage Anywhere, Anytime</h2>
          <p>Optimized for mobile use, Toro offers real-time updates and offline capabilities, allowing you to manage your ranch on the go, even in the most remote areas.</p>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Taylor'd Software Solutions | All rights reserved.</p>
        <ul className="footer-links">
          <li onClick={() => navigate('/privacy')}>Privacy Policy</li>
          <li onClick={() => navigate('/terms')}>Terms of Service</li>
        </ul>
      </footer>
    </div>
  );
};

export default LandingPage;
