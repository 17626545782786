import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSubscription } from './SubscriptionContext';
import axios from 'axios';

const ProtectedRoute = () => {
  const { subscription, setSubscription, loading, setLoading } = useSubscription();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const fetchSubscription = useCallback(async () => {
    try {
      setLoading(true);

      // Get the auth token
      const token = localStorage.getItem('authToken');
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      // Make the API call
      const response = await axios.get('https://toro-f592d52310e3.herokuapp.com/subscription', {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data) {
        setSubscription(response.data); // Update the context with the fetched subscription data
        setIsAuthenticated(true);
      } else {
        setSubscription(null); // No subscription found
        setIsAuthenticated(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setIsAuthenticated(false);
        } else if (error.response.status === 404) {
          setSubscription(null);
          setIsAuthenticated(false);
        } else {
          console.error('Error fetching subscription:', error.message);
        }
      } else {
        console.error('Network or server error:', error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [setLoading, setSubscription]);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);
  
  if (loading) {
    console.log('Loading...');
  }
  
  if (!isAuthenticated) {
    console.log('Not authenticated:', { isAuthenticated, subscription });
  }
  
  if (!subscription || !['active', 'trialing'].includes(subscription.status)) {
    console.log('Redirecting to manage membership:', subscription);
    return <Navigate to="/manage-membership" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;