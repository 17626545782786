import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SubscriptionProvider } from './SubscriptionContext'; 
import LandingPage from './pages/Website Home/LandingPage'; // Import Landing Page
import ProtectedRoute from './ProtectedRoute';

const stripePromise = loadStripe('your-stripe-key');

// Lazy loaded components
const WelcomeScreen = React.lazy(() => import('./pages/Login & Onboarding/Welcome'));
const LoginScreen = React.lazy(() => import('./pages/Login & Onboarding/Login'));
const SignUpScreen = React.lazy(() => import('./pages/Login & Onboarding/SignUp'));
const ForgotPassword = React.lazy(() => import('./pages/Login & Onboarding/ForgotPassword'));
const VerifyCode = React.lazy(() => import('./pages/Login & Onboarding/VerifyCode'));
const ResetPassword = React.lazy(() => import('./pages/Login & Onboarding/ResetPassword'));
const HomeScreen = React.lazy(() => import('./pages/User/Home'));
const ProfileScreen = React.lazy(() => import('./pages/User/Profile'));
const ManageRanchScreen = React.lazy(() => import('./pages/RanchManagement'));
const LivestockSearch = React.lazy(() => import('./pages/Livestock Management/LivestockSearch'));
const LivestockList = React.lazy(() => import('./pages/Livestock Management/LivestockList'));
const LivestockDetails = React.lazy(() => import('./pages/Livestock Management/LivestockDetails'));
const UpdateProfile = React.lazy(() => import('./pages/User/UpdateProfile'));
const ChangePassword = React.lazy(() => import('./pages/User/ChangePassword'));
const UpdateLivestock = React.lazy(() => import('./pages/Livestock Management/LivestockUpdate'));
const AddLivestock = React.lazy(() => import('./pages/Livestock Management/AddLivestock'));
const LocationHistory = React.lazy(() => import('./pages/Livestock Management/LocationHistory'));
const ImportScreen = React.lazy(() => import('./pages/Livestock Management/ImportScreen'));
const TruTest = React.lazy(() => import('./pages/Livestock Management/TruTestImport'));
const ManageMembership = React.lazy(() => import('./pages/User/ManageMembership'));
const PaymentScreen = React.lazy(() => import('./pages/User/Payment'));
const Privacy = React.lazy(() => import('./pages/Website Home/privacy'));  // Privacy component
const Terms = React.lazy(() => import('./pages/Website Home/terms'));  // Terms of Service component
const Contact = React.lazy(() => import('./pages/Website Home/contact'));  // Contact component
const ProductsPage = React.lazy(() => import('./pages/Website Home/ProductsPage')); // Products page
const PricingPage = React.lazy(() => import('./pages/Website Home/PricingPage'));  // Pricing page

function App() {
  return (
    <SubscriptionProvider>
      <Elements stripe={stripePromise}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Toro Livestock Management</title>
          <meta name="description" content="Manage your livestock with Toro's powerful tools." />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Landing Page */}
              <Route path="/" element={<LandingPage />} />

              {/* Products and Pricing Pages */}
              <Route path="/product" element={<ProductsPage />} />
              <Route path="/pricing" element={<PricingPage />} />

              {/* Authentication-related routes */}
              <Route path="/welcome" element={<WelcomeScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/signup" element={<SignUpScreen />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/verify-code" element={<VerifyCode />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              
              {/* User profile and membership management */}
              <Route path="/home" element={<HomeScreen />} />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="/manage-membership" element={<ManageMembership />} />
              <Route path="/payment" element={<PaymentScreen />} />
              <Route path="/manage-ranch" element={<ManageRanchScreen />} />

              {/* Legal pages */}
              <Route path="/privacy" element={<Privacy />} />  {/* Privacy route */}
              <Route path="/terms" element={<Terms />} />      {/* Terms route */}
              <Route path="/contact" element={<Contact />} />  {/* Contact route */}

              {/* Protected Routes (require authentication) */}
              <Route element={<ProtectedRoute />}>
                <Route path="/livestock-search" element={<LivestockSearch />} />
                <Route path="/livestock-list" element={<LivestockList />} />
                <Route path="/livestock-details" element={<LivestockDetails />} />
                <Route path="/livestock-update" element={<UpdateLivestock />} />
                <Route path="/add-livestock" element={<AddLivestock />} />
                <Route path="/location-history" element={<LocationHistory />} />
                <Route path="/import-screen" element={<ImportScreen />} />
                <Route path="/trutest" element={<TruTest />} />
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </Elements>
    </SubscriptionProvider>
  );
}

export default App;
